@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
$myColor:#3498db;
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
  user-select: none;
}
/* custom scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $myColor;
  border-radius: 10px;
}
/* navbar section */
.navbar-brand img{
  height: 40px;
  width: auto;
  transform: scale(1.4);
}
.navbar a{
  font-size: 1.3rem;
  text-transform: capitalize;
}
.navbar-brand{
  color: $myColor !important;
}
.menu_active{
  font-weight: bold;
  color: #3598db !important;
  border-bottom: 3px solid #3598db;
}
.navbar a:hover{
  color: #3598db !important;
}
.alerts{
  height: 22px;
}

/* Main page or home page */
#header{
  width: 100%;
  height: 90vh;
  overflow-x: hidden;
}
#header h1{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}
#header .brand-name{
  color: $myColor;
}
#header h2{
  color: #484848;
  font-size: 24px;
}
#header .btn-get-started{
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s ease;
  color: $myColor;
  border: 2px solid $myColor;
}
#header .btn-get-started:hover{
  background: $myColor;
  color: white;
}
#header img{
  text-align:right;
  height: 130px !important;
  transition: all .5s ease;
}
#header img:hover{
  height: 150px !important;
}
#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  transition: all .5s ease;
}
@-webkit-keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}

#header .animated1{
  animation: down-up 2s ease-in-out infinite alternate-reverse both;
  transition: all .5 ease;
}

@-webkit-keyframes  down-up{
  0%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(20px);
  }
}
@keyframes down-up{
  0%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(20px);
  }
}
/* responsive query start */
@media (max-width:500px){

  #header{
    height: 100%;
  }
  .navbar-brand img{
    height: 25px;
    margin-left: -40%;
    margin-top: -15%;
  }
  #header h1{
    font-size: 30px;
  }
  #header .info{
    margin-top: -25%;
  }
  #header img{
    text-align:right;
    height: 52px !important;
  }
  #header img:hover{
    height: 52px !important;
  }
  #header .btn-get-started{
    font-size: 18px;
    padding: 5px 15px;
    border-radius: 50px;
  }
  footer{
    font-size: 10px;
    margin-top: 5%;
  }
}