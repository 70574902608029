$myColor: #3498db; 
#project{
    height: 90vh;
    width: 100%;
    overflow-x: hidden;
}
#project .carousel{
    height: 77vh;
}
#project .carousel-inner{
    margin-left: 10%;
    width: 80%;
    height: 100% !important;
    border-radius: 20px;
    transition: all .5s ease;
    border: 2px solid $myColor;   
}
#project .carousel-inner:hover{
    transform: scale(1.02);
}
#project img{
    margin-left: 3%;
    border: 5px solid $myColor !important;
    height: 430px;
    width: 95% !important;
    border-radius: 20px;
    background: none;
}
#project .ptext{
    float: right;
    font-size: 20px;
}
#project .ptext li{
    margin-top: 5px;
}
#project .ptext h1{
    margin-bottom: 25px;
}
#project .ptext ul span{
    color: $myColor;
    margin-right: 10px;
}
#project .carousel-indicators [data-bs-target] {
    background-color: $myColor !important;
}
#project .carousel-control-prev-icon,.carousel-control-next-icon{
    transform: scale(1.5);
    background-color: $myColor;
}

@media (max-width:"500px"){
    #project .carousel{
        margin-top: -10%;
    }
    #project .carousel-inner{
        margin-left: 2%;
        margin-right: 2%;
        width: 96%;
        height: 100%;
    }
    #project .carousel-inner:hover{
        transform: none;
    }
    #project img{
        margin-left: 2.5%;
        border: 2px solid $myColor !important;
        height: 180px;
        border-radius: 10px;
    }
    #project .ptext{
        font-size: 12px;
        margin-left: 0;
        margin-right: 0;
    }
    #project .ptext h1{
        margin-left: -5% !important;
        margin-bottom: 10px;
    }
    #project .text{
        margin: 0 !important;
        padding: 0 !important;
    }
    .carousel-control-next, .carousel-control-prev{
        display: none;
    }
}