$myColor: #3498db; 

#about{
    height: 90vh;
    width: 100%;
    overflow: hidden;
}
#about ul li{
    margin-top: 10px;
}
#about ul li span{
    color: $myColor;
}
.btn-get-started{
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    background: none;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s ease;
    color: $myColor;
    border: 2px solid $myColor;
}
.btn-get-started:hover{
    background: $myColor;
    color: white;
}

@media (max-width:"500px"){
    #about{
        height: auto;
    }
    .circle{
        justify-content: space-around;
    }
}