.circularbar{
    width: 120px;
    height: 120px;
}

.cText{
    color: #3498db;
}

@media (max-width:"500px"){
    .circularbar{
        width: 140px;
        height: 140px;
    }
    .cText{
        font-size: 18px;
    }
}